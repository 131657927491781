<div class="panel-container">
    <div class="header-title">
        <ng-content select="[header-title]"></ng-content>
    </div>
    <div class="header-filter">
        <div class="filters">
            <ng-content select="[header-filter]"></ng-content>
        </div>
        <div class="buttons">
            <ng-content select="[header-buttons]"></ng-content>
        </div>
    </div>
    <div class="container-body">
        <div class="body-header">
            <div class="body-header_actions">
                <ng-content select="[body-actions]"></ng-content>
            </div>
            <div class="body-header_info">
                <ng-content select="[body-info]"></ng-content>
            </div>
        </div>
        <div class="body-content">
            <ng-content select="[body-content]"></ng-content>
        </div>
    </div>
</div>