import { Component, OnInit, Renderer2 } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoaderService } from './shared/services/loader-service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  pleasStatus: boolean;
  constructor(private config: PrimeNGConfig,
    private loaderService: LoaderService,
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2) { }

  public ngOnInit(): void {
    this.titleService.setTitle("HRclick - כלים דיגיטליים חדשניים בתחום ה HR");
    const description = "מעולם זה לא היה פשוט קל ודיגיטלי כל כך לבצע תהליך קליטת עובד באופן מלא בעסק שלכם. " +
      "הכל גם מותאם לטלפון הנייד וכמובן בכל הנוגע לחלק הפנסיוני בשיתוף עם סוכן / נת הביטוח שלכם." +
      "פחות מדקה נרשמתם והתחלתם לעבוד!!!" +
      "HRclick חדשנות ואפקטיביות בתחום ה- HR מבית חברת Pay-it"
    this.metaService.addTags([
      { name: 'keywords', content: 'טופס 101, הסכם העסקה, הסכם עבודה,טופס סודיות,הצטרפות לקרן פנסיה,הצטרפות פנסיונית,קליטת עובד חדש' },
      { name: 'description', content: description },
      { name:'facebook-domain-verification',content:'xaavz5dz67l810j1nwyplg04lxkyx9'}
    ]);
    this.config.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
      dayNamesShort: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
      dayNamesMin: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
      monthNames: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
      monthNamesShort: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
      today: 'היום',
      clear: 'נקה',
      weekHeader: 'Wk'
    });


  }


  ngAfterViewInit() {

    this.loaderService.httpProgress().subscribe((status: boolean) => {
      this.pleasStatus = status;
    });

  }

}
