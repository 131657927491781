
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponseDTO, RefreshToken } from './../../projects/main-app/login/DTOs/index';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { APIEndpoints } from 'src/app/shared/models/ApiEndPoints.model';
@Injectable()
export class TokenHandlerService {

    constructor(
        private readonly httpClient: HttpClient) { }

    public refreshTokenExpiration(req: RefreshToken): void {
        const url = `${environment.baseUrl}/${APIEndpoints.REFRESH_TOKEN}`;
        this.httpClient.post<LoginResponseDTO>(url, req)
            .pipe(
                take(1)
            ).subscribe((res: LoginResponseDTO) => {
                sessionStorage.setItem('token', res.accessToken);
                sessionStorage.setItem('refreshToken', res.refreshToken);
            });
    }

    public checkUserRole(): Observable<boolean> {
        const url = `${environment.baseUrl}/${APIEndpoints.GET_USER_ROLE}`;
        return this.httpClient.get<boolean>(url)
            .pipe(map(response => response))
    }

    public getUserCreditsStats(): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.GET_USER_CREDITS_STATS}`;
        return this.httpClient.get<any>(url)
            .pipe(map(response => response))
    }

}
