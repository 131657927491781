import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ServerErrorResponse } from '../../models/Error-server.model';



@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public error: ServerErrorResponse;
  public modalContent: SafeHtml;
  public header: string;
  public isError: boolean = true;
  public isApprove: Boolean = false;
  public isLogout: Boolean = false;
  public button1: string
  public button2: string

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isApprove = this.config.data['approve'];
    this.isLogout = this.config.data['logout'];
    this.button1 = this.config.data['button1'];
    this.button2 = this.config.data['button2'];
    if (this.config.styleClass == 'success') {
      this.isError = false;
    }

    setTimeout(() => {
      this.config.width = "500px";
      this.modalContent = this._sanitizer.bypassSecurityTrustHtml(this.config.data['text']);
      this.header = this.config.header;
      this.config.header = "";
      if(this.isLogout){
        this.config.closable = false;
      }
    }, 0);
    // this.error = this.config.data.error;


  }

  approveDelete(approve) {
    this.ref.close(approve);
  }

  handleClick(type){
    this.ref.close(type);
  }

}

