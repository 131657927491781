import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showMenu() {
    let menu = document.getElementsByClassName("side-menu-inner")[0];
    if (menu != undefined) {
      //menu.classList.remove("mobile-hide");
      menu["style"].width = "100%";
    }
  }

  menuActive(){
    return document.getElementsByClassName("side-menu-inner")[0] ? true : false;
  }

}
