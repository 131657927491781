import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { HeaderComponent } from './components/header/header.component';
import { ButtonComponent } from './components/button/button.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CardBorderDirective } from './directives/card-border.directive';
import { InfoPrepareStepComponent } from './components/info-prepare-step/info-prepare-step.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelComponent } from './components/panel/panel.component';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ValidationErrorService } from './services/validation-error.service';
import { FormErrorMsgComponent } from './components/form-error-msg/form-error-msg.component';
import { TokenInterceptor } from './interceptors/jwt-token.interceptor';
import { ServerErrorHandlerService } from './services/error-handler.service';
import { ModalComponent } from './components/modal/modal.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoaderInterceptor } from './interceptors/loader-Interceptor'
import { SlipAuthGuard } from './guards/auth.slipGuard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelChoiceComponent } from './components/model-choice/model-choice.component';
import { TokenHandlerService } from './services/token-handler-service';
import { AdminAuthGuard } from './guards/auth.adminGuard';
import {PaginatorModule} from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [
    HeaderComponent,
    ButtonComponent,
    CardBorderDirective,
    InfoPrepareStepComponent,
    PanelComponent,
    FormErrorMsgComponent,
    ModalComponent,
    ModelChoiceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RadioButtonModule,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    InputSwitchModule,
    CheckboxModule,
    CalendarModule,
    InputTextareaModule,
    DynamicDialogModule,
    PaginatorModule,
    NgbModule,
    TabViewModule
  ],
  providers: [
    AuthGuard,
    SlipAuthGuard,
    AdminAuthGuard,
    ValidationErrorService,
    TokenHandlerService,
    ServerErrorHandlerService,
    DialogService, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  exports: [
    HeaderComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CardBorderDirective,
    RadioButtonModule,
    InfoPrepareStepComponent,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    PaginatorModule,
    InputSwitchModule,
    CheckboxModule,
    NgbModule,
    PanelComponent,
    CalendarModule,
    InputTextareaModule,
    FormErrorMsgComponent,
    ModalComponent,
    DynamicDialogModule,
    TabViewModule
  ],
})
export class SharedModule { }
