import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationErrorService } from '../../services/validation-error.service';

@Component({
  selector: 'app-form-error-msg',
  templateUrl: './form-error-msg.component.html',
  styleUrls: ['./form-error-msg.component.scss'],
})
export class FormErrorMsgComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Input() context: any;
  constructor(public validationErrorService: ValidationErrorService) { }

  ngOnInit(): void {
  }

}
