import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorHandlerService } from '../services/error-handler.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  errorCode: { [key: number]: string } = {
    [120]:'המשתמש לא קיים במערכת',
    [125]:'סיסמה לא תקינה',
    [130]:'מעסיק לא נמצא',
    [140]:'עובד לא נמצא',
    [150]:'בקשה לא נמצא',
    [160]:'טופס לא נמצא',
    [10]:'המשתמש כבר קיים במערכת',
    [170]:'קוד חד פעמי לא תקין',
    [180]:'יצירת קוד חד פעמי נכשלה',
    [190]:'הזנת קוד חד פעמי שתוקפו פג. אנא לחץ על שלח שוב.',
    [200]:'יצירת תיוק כללי בתיק עובד נכשלה.',
    [210]:'יצירת התפטרות בתיק עובד נכשלה.',
    [220]:'יצירת שימוע בתיק עובד נכשלה.',
    [230]:'שמירת קובץ בתיק עובד נכשלה.',
    [240]:'מחיקת קובץ מתיק עובד נכשלה.',
    [250]:'תעודת זהות שהוזנה כבר קיימת בתיק עובד אחר.',
    [260]:'קובץ לא קיים.'
   }; 

  constructor(
      private errorHandlerService: ServerErrorHandlerService,
      private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(this.errorHandlerService.errorHandler.bind(this))
      )
  }
}
