import { Injectable } from '@angular/core';
import { EmployeeHttpService } from '../../services/http/employee.service';
import { EmployeeDashboardMetaData } from '../models/employee.meta-data.model';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { hebrewPattern, validateOptionalField, requiredByOtherFieldByVal, numberPatternOptional } from '../../../../shared/helpers/validatorsFn.helper';
import { EmployeeDTO, CopySalaryRequest, ProdeuceSalaryRequest } from '../../models/DTOs/Employee.dto';
import { ValidWizardStepNumber, ValidWizardStepForm, NewEmployeeWizardStep1, NewSlipWizardStep1 } from '../models/employer-wizard.model';
import { IFormGroup } from '@rxweb/types';

@Injectable()
export class EmployeeListService {
    public getCurrentEmployees$: BehaviorSubject<void> = new BehaviorSubject(null);
    private allEmployees$: BehaviorSubject<EmployeeDashboardMetaData[]> = new BehaviorSubject([]);
    private allEmployees: EmployeeDashboardMetaData[] = [];
    private _employerId: number;
    constructor(
        private employeeHttpService: EmployeeHttpService,
        private formBuilder: FormBuilder
    ) {
        //this.getEmployeesList();
        let eid = localStorage.getItem("employerId");
        if (eid) {
            this.employerId = Number(eid)
        }
    }

    public getEmployeesList(id:number, date = ""): void {
        this.getCurrentEmployees$
            .pipe(
                switchMap(() => this.employeeHttpService.getEmployeesList(id, date)),
            ).subscribe((res: EmployeeDashboardMetaData[]) => {
                this.allEmployees$.next(res);
                this.allEmployees = res;
            });
    }

    public get employees$(): Observable<EmployeeDashboardMetaData[]> {
        return this.allEmployees$.asObservable();
    }

    public get employees(): EmployeeDashboardMetaData[] {
        return this.allEmployees;
    }

    public get employerId(): number {
        return this._employerId;
    }

    public set employerId(id: number) {
        this._employerId = id;
    }

    public BuildForm(employee: any): FormGroup {
        let myForm: FormGroup;
        myForm = this.formBuilder.group({
            firstName: [employee.firstName, [Validators.required, hebrewPattern()]],
            lastName: [employee.lastName, [Validators.required, hebrewPattern()]],
            cellphoneNum: [employee.cellphoneNum, [numberPatternOptional(10, 10)]],
            hourRate: [employee.hourRate, [Validators.required]],
            grossSalary: [employee.grossSalary, [Validators.required]],
            globalOvertime: [employee.globalOvertime, [Validators.required]],
            totalHour:[employee.totalHour, [Validators.required]],
            numberOfVacationDays: [employee.numberOfVacationDays],
            numberOfSickDays: [employee.numberOfSickDays],
            vacationDayExploitation: [employee.vacationDayExploitation],
            sickDayExploitation: [employee.sickDayExploitation],
            mealExpenses: [employee.mealExpenses],
            travellingExpenses: [employee.travellingExpenses],
            WageType: [employee.wageType]
        },
            {
                validators: [
                    // validateOptionalField("cellphoneNum", 10, 10),
                    requiredByOtherFieldByVal('WageType', 'grossSalary', true),
                    requiredByOtherFieldByVal('WageType', 'hourRate', false),
                ]
            });

        return myForm;
    }

    public onSubmitUpdateEmployer(employee: any, form: FormGroup,api:string) {
        employee.edit = false;
        const controls = form.controls;

        employee.firstName = controls.firstName.value;
        employee.lastName = controls.lastName.value;
        employee.cellphoneNum = controls.cellphoneNum.value;
        employee.hourRate = Number(controls.hourRate.value);
        employee.grossSalary = Number(controls.grossSalary.value);
        employee.globalOvertime = Number(controls.globalOvertime.value);  
        employee.totalHour = Number(controls.totalHour.value);          
        employee.numberOfVacationDays = Number(controls.numberOfVacationDays.value);
        employee.numberOfSickDays = Number(controls.numberOfSickDays.value);
        employee.vacationDayExploitation = Number(controls.vacationDayExploitation.value);
        employee.sickDayExploitation = Number(controls.sickDayExploitation.value);
        employee.mealExpenses = Number(controls.mealExpenses.value);
        employee.travellingExpenses = Number(controls.travellingExpenses.value);
        employee.WageType = controls.WageType.value;
        employee.employerId = employee.employerId;

        this.employeeHttpService[api](employee).
            subscribe((res) => {
                console.log(res)
                this.getEmployeesList(employee.employerId,employee.salaryDate);
            })

    }

    CopySalaryDetails(req: CopySalaryRequest) {
        this.employeeHttpService.CopySalaryDetails(req).subscribe((res) => {
            console.log(res)
            //this.getCurrentEmployees$.next();
        })
    }

    produceSalarySlip(req: ProdeuceSalaryRequest): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.employeeHttpService.produceSalarySlip(req)
                .pipe(
                    take(1)
                ).subscribe((res) => {
                    observer.next(res)
                    observer.complete()
                })
        })
    }

    public getSalarySlip(employeeId: number,employerId: number, salaryDate: string): void{
        this.employeeHttpService.getSalarySlip(employeeId,employerId, salaryDate)
            .subscribe((res) => {        
                 let win = window.open();
                 let src = "data:application/pdf;base64," + res["file"];
                 win.document.write('<iframe src='+src+' frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            })

    }
}