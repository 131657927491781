import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationErrorService {

    private errorMessages = new Map<ErrorName, (context?: any) => string>([
        ['required', this._required],
        ['email', this._email],
        ['minlength', this._minLength],
        ['hebrewPattern', this._hebrewPattern],
        ['numberPattern', this._numberPattern],
        ['emptyArray', this._emptyArray],
        ['onlyNumbers', this._onlyNumber],
        ['freeValidationText', this._freeValidationText],
        ['mustMatch', this._MustMatch],
        ['companyExist', this._companyExist],
        ['max', this._max],
        ['atLeastOneValidator', this._atLeastOneValidator],
        ['ageValid', this._ageValid],
        ['numbersRange', this._numbersRange],

    ]);

    public getErrorDesc(errorName: string, context?: any): string {
        const validator = this.errorMessages.get(errorName as ErrorName);
        if (!validator) {
            return null;
        }
        return validator.bind(this)(context);
    }

    private _required(): string {
        return 'זהו שדה חובה'
    }

    private _companyExist(): string {
        return 'המעסיק כבר קיים במערכת'
    }

    private _email(): string {
        return 'יש להזין מייל ולידי'
    }

    private _MustMatch() {
        return 'הסיסמה לא תואמת'
    }

    private _minLength(length: number): string {
        return `יש להזין לפחות ${length} תווים`
    }

    private _max(max: number): string {
        return `יש להזין ערך עד ${max}`
    }

    private _atLeastOneValidator(amount: number): string {
        return `יש למלא שדה אחד לפחות מכל ה ${amount}`
    }

    private _hebrewPattern(): string {
        return 'יש להזין רק אותיות בעברית'
    }

    private _ageValid(age): string {
        return `גיל חייב להיות גדול מ-${age}`
    }


    private _numberPattern(err): string {
        let a = err.split(',')
        if (a[0] == a[1])
            return `יש להזין רק מספרים ${a[0]} תווים בלבד`
        else
            return `יש להזין טווח ספרות בין ${a[0]} ל ${a[1]}`
    }

    private _numbersRange(err): string {
        let a = err.split(',')
        return `יש להזין ערך בין ${a[0]} ל ${a[1]}`
    }

    private _freeValidationText(text: string): string {
        return text;
    }

    private _onlyNumber(): string {
        return 'ניתן להזין רק מספרים'
    }

    private _emptyArray(): string {
        return 'חובה לבחור לפחות אחת מהאופציות'
    }

    public isFieldError(formGroup: FormGroup, name: string): boolean {
        return formGroup.get(name) && formGroup.get(name).touched && !formGroup.get(name).valid;
    }

    public getErrorName(field: AbstractControl): string {
        return Object.keys(field.errors)[0];
    }

}

export type ErrorName = 'required' | 'email' | 'minlength' | 'hebrewPattern' | 'numberPattern' | 'emptyArray' |
    'onlyNumbers' | 'freeValidationText' | 'mustMatch' | 'companyExist' | 'max' | 'atLeastOneValidator' | 'ageValid' | 'numbersRange';

export interface ValidationError { [key: string]: any; }






