import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private httpLoading$  = new ReplaySubject<boolean>(1);

  constructor() {
    //console.log('ctor');

   }

  httpProgress():  Observable<boolean> {
    //console.log('httpProgress')
    
    return this.httpLoading$.asObservable();
    
  }

  setHttpProgressStatus(inprogess: boolean) {
    //console.log('setHttpProgressStatus',inprogess, this.httpLoading$);
 
    this.httpLoading$.next(inprogess);
  }
  
}