<div class="choiceModal" *ngIf="isApprove">

  <div class="">
    <div class="">
      <div class="">
        <div class="header-title">
          <h4><img src="assets/icons-percent-blue.svg" alt="icon"> {{header}}</h4>
        </div>

        <ng-container *ngFor="let benefit of benefits">
          <div class="info-and-field">
            <p-checkbox [binary]="true" [(ngModel)]="benefit.model"></p-checkbox>
            <div class="card-box carExpensesChecked">
              <div class="card-box-top">
                <img [src]="benefit.icon">
                <div class="title">
                  {{benefit.title}}
                </div>

              </div>
            </div>
          </div>
        </ng-container>
        <br>
        <br>
        <div class="approve" *ngIf="isApprove">
          <app-button isSmall="true" width="150" class="dark first-btn approveBtn" (execute)="approveAction()"
            text="אישור"></app-button>
        </div>

      </div>
    </div>

  </div>
</div>