import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { APIEndpoints } from '../models/ApiEndPoints.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private static Request: HttpRequest<any>;
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('token');
    TokenInterceptor.Request = request;
    if (token && request.url.includes(environment.baseUrl) && (!request.url.includes(APIEndpoints.LOGIN) || 
    !request.url.includes(APIEndpoints.CREATE_USER))) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}
