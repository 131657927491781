<div *ngIf="isError && !isApprove && !button1">

    <div class="container">
        <div class="main">
            <div class="card" appCardBorder>
                <div class="header-title">
                    <h4><img src="assets/icons-clinets-blue.svg" alt="icon"> {{header}}</h4>
                </div>
                <div class="icon">

                </div>
                <div class="text">
                    <div class="reguler" [innerHTML]="modalContent"></div>
                </div>

            </div>
        </div>

    </div>
</div>
<div *ngIf="!isError && !isApprove && !button1">
    <div class="main">
        <div class="header-title">
            <h4><img src="assets/icons-clinets-blue.svg" alt="icon"> {{header}}</h4>
        </div>
        <div class="card" appCardBorder>
            <div class="icon">
                <img src="assets/icons-success.svg" alt="icon">
            </div>
            <div class="text">
                <div class="reguler" [innerHTML]="modalContent"></div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="isApprove && !button1">

    <div class="container">
        <div class="main">
            <div class="card" appCardBorder>
                <div class="header-title">
                    <h4><img src="assets/icons-clinets-blue.svg" alt="icon"> {{header}}</h4>
                </div>
                <div class="icon">

                </div>
                <div class="text">
                    <div class="reguler" [innerHTML]="modalContent"></div>
                </div>
                <div class="approve" *ngIf="isApprove">
                    <app-button isSmall="true" width="150" class="dark first-btn approveBtn" (execute)="approveDelete(true)"
                        text="אישור"></app-button>
                    <app-button *ngIf="isLogout" isSmall="true" width="150" class="dark first-btn approveBtn"
                        (execute)="approveDelete(false)" text="התנתק"></app-button>
                </div>

            </div>
        </div>

    </div>
</div>

<div *ngIf="button1 && !width">

    <div class="container">
        <div class="main">
            <div class="card" appCardBorder>
                <div class="header-title">
                    <h4><img src="assets/icons-clinets-blue.svg" alt="icon"> {{header}}</h4>
                </div>
                <div class="icon">

                </div>
                <div class="text">
                    <div class="reguler" [innerHTML]="modalContent"></div>
                </div>
                <div class="approve">
                    <app-button isSmall="true" width="150" class="dark first-btn approveBtn"
                        (execute)="handleClick(true)" [text]="button1"></app-button>
                    <app-button *ngIf="button2" isSmall="true" width="150" class="dark first-btn approveBtn"
                        (execute)="handleClick(false)" [text]="button2"></app-button>
                </div>
            </div>
        </div>

    </div>
</div>
<div *ngIf="button1 && width">

    <div class="container">
        <div class="main">
            <div>
                <div class="header-title">
                    <h4>{{header}}</h4>
                </div>
                <div class="text" style="padding-bottom: 50px;">
                    <div class="reguler" [innerHTML]="modalContent"></div>
                </div>
                <div class="approve">
                    <app-button isSmall="true" width="384" class="dark first-btn approveBtn"
                        (execute)="handleClick(true)" [text]="button1"></app-button>
                    <app-button *ngIf="button2" isSmall="true" width="150" class="dark first-btn approveBtn"
                        (execute)="handleClick(false)" [text]="button2"></app-button>
                </div>
            </div>
        </div>

    </div>
</div>