
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class SlipAuthGuard implements CanActivate {

    constructor(private readonly router: Router) { }
    public canActivate(): boolean {
        const token = sessionStorage.getItem('token');
        if (!token) {
            this.router.navigate(['login']);
            return false;
        }
        if (token && !environment.slipClickOn) {
            this.router.navigate(['main']);
            return false;
        }
        
        return true;
    }
}