import { Component, OnInit } from '@angular/core';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ServerErrorResponse } from '../../models/Error-server.model';
import { NewSlipWizardService } from 'src/app/projects/slip-click/dashboard/services/new-slip-wizard.service';
@Component({
  selector: 'app-model-choice',
  templateUrl: './model-choice.component.html',
  styleUrls: ['./model-choice.component.scss']
})
export class ModelChoiceComponent implements OnInit {
  public error: ServerErrorResponse;
  public modalContent: string;
  public header: string;
  public isError: boolean = true;
  public isApprove: Boolean = false;
  public benefits: any[];
  constructor(
    private ref: DynamicDialogRef,
    private newSlipWizardService: NewSlipWizardService,
    private config: DynamicDialogConfig) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.isApprove = this.config.data['approve'];
      if (this.config.styleClass == 'success') {
        this.isError = false;
      }
      // this.config.closable = false;
      this.benefits = this.config.data['benefits'];
      this.config.width = "689px";
      this.modalContent = this.config.data['text'];
      this.header = this.config.header;
      this.config.header = "";
    }, 0);
    // this.error = this.config.data.error;


  }

  // send what you need to build
  approveAction() {
    this.ref.close(this.benefits); //close the Dialog;

  }

}


