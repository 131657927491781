
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private readonly router: Router) { }
    public canActivate(): boolean {
        const token = sessionStorage.getItem('token');
        if (!token) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}