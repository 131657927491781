import { identifierModuleUrl } from '@angular/compiler';
import { AbstractControl, FormGroup } from '@angular/forms';

export const hebrewPattern = () => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!/^[א-ת\s]+$/.test(control.value)) {
            return { hebrewPattern: true }
        }
    }
}

export const ageValid = () => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value) {
            // let dateParts = control.value.split("/");
            // let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            let ageDifMs = Date.now() - control.value.getTime();
            let ageDate = new Date(ageDifMs); // miliseconds from epoch
            let year = control.value.getUTCFullYear();
            if (year > new Date().getFullYear() || Math.abs(ageDate.getUTCFullYear() - 1970) < 16) {
                return { ageValid: true }
            }
        }
    }
}


export const hebrewPatternNumberPattern = () => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!/^[0-9 א-ת\s]+$/.test(control.value)) {
            return { hebrewPatternNumberPattern: true }
        }
    }
}

export const emailPattern = () => {

    return (control: AbstractControl): { [key: string]: any } => {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(control.value)) {
            return { emailPattern: true }
        }
    }
}

export const numberPattern = (fromrange: number, torange: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!new RegExp("^[0-9]{" + fromrange + "," + torange + "}$").test(control.value)) {
            return { numberPattern: true }
        }
    }
}

export const numberPatternRange = (fromrange: number, torange: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value && control.value != "") {
            if (!new RegExp("^[0-9]").test(control.value) || control.value > torange || control.value < fromrange)
                return { numbersRange: true }
        }
    }
}

export const numberPatternOptional = (fromrange: number, torange: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value && control.value !== "") {
            if (!new RegExp("^[0-9]{" + fromrange + "," + torange + "}$").test(control.value)) {
                return { numberPattern: true }
            }
        }
    }
}

export const mobileNumberPattern = (amount: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!new RegExp("^(05)([0-9]{" + (amount - 2) + "})$").test(control.value)) {
            return { freeValidationText: true }
        }
    }
}

export const numberPatternStartWith = (amount: number, start: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!new RegExp("^(" + start + ")([0-9]{" + (amount - 1) + "})$").test(control.value)) {
            return { freeValidationText: true }
        }
        let b = isValidIsraeliID(control.value);
        if (!b)
            return { freeValidationText: true };
    }
}

export const numberPatternStartWithOrValidId = (amount: number, start: number) => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!new RegExp("^(" + start + ")([0-9]{" + (amount - 1) + "})$").test(control.value) && (!control.value || control.value && !control.value.length)) {
            return { freeValidationText: true }
        }
        let b = isValidIsraeliID(control.value);
        if (!b)
            return { freeValidationText: true };
    }
}


export const onlyNumbers = () => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value && control.value != "") {
            if (!new RegExp("^[0-9]").test(control.value)) {
                return { onlyNumbers: true }
            }
        }
    }
}

export const requiredByOtherField = (outerControl: string, effectedControl: string) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (control.value && !efControl.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export const atLeastOneValidator = (controls: string[], checked: string) => {
    return (formGroup: FormGroup) => {
        if (controls && formGroup.controls[checked].value) {
            let errIndex = -1;
            for (let index = 0; index < controls.length; index++) {
                const element = formGroup.controls[controls[index]]
                if (element.value && element.value !== '') {
                    errIndex = index;
                    break;
                }
            }
            if (errIndex == -1) {
                formGroup.controls[controls[0]].setErrors({ atLeastOneValidator: true });
            }
            else {
                controls.forEach(element => {
                    formGroup.controls[element].setErrors(null);
                });
            }
        }
        else {
            controls.forEach(element => {
                formGroup.controls[element].setErrors(null);
            });
        }
    }
}
export const requiredByOtherFieldByVal = (outerControl: string, effectedControl: string, valToMatch: any) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (control.value === valToMatch && !efControl.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}
export const requiredByOtherFieldByValues = (outerControl: string, effectedControl: string, valToMatchs: number[]) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (valToMatchs.includes(control.value) && !efControl.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}

export const requiredByOtherListFieldByValues = (outerControl: string, effectedControl: string, valToMatchs: any[]) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (control.value.length === 0){
            efControl.setErrors(null);
        }
        else{
            for (let i = 0; i < control.value.length; i++){
                if (valToMatchs.includes(control.value[i]) && !efControl.value) {
                    efControl.setErrors({ required: true });
                    break;
                } else if (!efControl.valid && efControl.hasError('required') || !control.value[i]) {
                    efControl.setErrors(null);
                }
            } 
        }       
    }
}

export const requiredByTwoFieldByVal = (mainControl: string, outerControl: string, effectedControl: string, valToMatch: any) => {
    return (formGroup: FormGroup) => {
        const main = formGroup.controls[mainControl];
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (control.value === valToMatch && !efControl.value && main.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}
export const requiredBy2FieldBy2Val = (firstControl: string, secondControl: string, effectedControl: string, status: any) => {
    return (formGroup: FormGroup) => {
        const fcontrol = formGroup.controls[firstControl];
        const scontrol = formGroup.controls[secondControl];
        const efControl = formGroup.controls[effectedControl];
        if (fcontrol.value === status && !efControl.value && scontrol.value == false) {
            efControl.setErrors({ required: true });
        }// else if (!efControl.valid && efControl.hasError('required') || !fcontrol.value) {	
        //     efControl.setErrors(null);	
        // }	
    }
}
export const validateOptionalField = (name, val1, val2) => {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[name];
        control.valueChanges.subscribe(() => {
            if (control.value === "") {
                control.clearValidators();
            } else {
                control.setValidators(numberPattern(val1, val2));
            }
            control.updateValueAndValidity({ emitEvent: false });
        });
    }
}
export const requiredByOtherFieldByValNotEqual = (outerControl: string, effectedControl: string, valToMatch: number) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (control.value !== valToMatch && !efControl.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}
export const requiredByOtherFieldByValuesNotEqual = (outerControl: string, effectedControl: string, valToMatchs: number[]) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[outerControl];
        const efControl = formGroup.controls[effectedControl];
        if (!valToMatchs.includes(control.value) && !efControl.value) {
            efControl.setErrors({ required: true });
        } else if (!efControl.valid && efControl.hasError('required') || !control.value) {
            efControl.setErrors(null);
        }
    }
}

export const notEmptyArray = () => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value || control.value && !control.value.length) {
            return { emptyArray: true }
        }
    }
}

function isValidIsraeliID(id: any) {
    id = String(id).trim();
    if (id.length != 9) {
        while (id.length < 9) {
            id = "0" + id;
        }
    }

    // Pad string with zeros up to 9 digits
    //	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}
export const isValidID = () => {

    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value || control.value && control.value.length>9) {
            return { freeValidationText: true };
        }

        let b = isValidIsraeliID(control.value);
        if (!b || Number(control.value) == 0)
            return { freeValidationText: true };
        /*
            let idnumber : number =  control.value;
            let id = String(control.value).trim();
          
            if (id.length > 9 || id.length < 5 || isNaN(idnumber)) 
              return {freeValidationText: true };
            
            
              id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            let isvalid = ()=>{  return Array
                    .from(id, Number)
                      .reduce((counter, digit, i) => {
                        const step = digit * ((i % 2) + 1);
                        return counter + (step > 9 ? step - 9 : step);
                    }) % 10 === 0;
                }
              
                if(isvalid())
                 return {freeValidationText: true };
           */






    }


}

export function buildDateStr(date: any) {
    if (date.indexOf("T") > 0) {
        const resDate = new Date(date);
        return resDate.getDate() + "/" + (resDate.getMonth() + 1) + "/" + resDate.getFullYear();
    }
    return date;
}

