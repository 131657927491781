
<div class="error-container">
    <ng-container *ngIf="validationErrorService.isFieldError(formGroup, fieldName)">
        <ng-container
            *ngTemplateOutlet="errorMessage; context: { error: validationErrorService.getErrorName(formGroup.get(fieldName)), context: context }">
        </ng-container>
    </ng-container>
</div>

<ng-template #errorMessage let-context="context" let-error="error">
    <div class="error-msg">
        {{validationErrorService.getErrorDesc(error, context)}}
    </div>
</ng-template>
