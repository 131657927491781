import { WizardStepInfo } from 'src/app/shared/models/Wizard.model';

export enum RouterPaths {
    DASHBORD = '/slip-click/dashboard',
    EMPLOYERS = '/slip-click/dashboard/employers',
    NEW_EMPLOYERS_START = '/slip-click/dashboard/employers/employer-new-start',
    EMPLOYER_WIZARD_DETAILS = '/slip-click/dashboard/employers/employer-wizard/details',
    EMPLOYER_WIZARD_REPRESENTATIVE = '/slip-click/dashboard/employers/employer-wizard/representative',
    EMPLOYER_WIZARD_SALLARY = '/slip-click/dashboard/employers/employer-wizard/sallary',
    EMPLOYER_WIZARD_SUMMARY = '/slip-click/dashboard/employers/employer-wizard/summary',
    SUCCESS_NEW_EMPLOYER = '/slip-click/dashboard/employers/success-new-employer',
    SUCCESS_NEW_EMPLOYEE = '/slip-click/dashboard/employers/success-new-employee',
    SUCCESS_NEW_SLIP = '/slip-click/dashboard/employers/success-new-slip',
    SUCCESS_EDIT_EMPLOYEE = '/slip-click/dashboard/employers/success-edit-employee',
    EMPLOYEE_WIZARD_DETAILS = '/slip-click/dashboard/employers/employee-wizard/details',
    EMPLOYEE_WIZARD_FAMILY = '/slip-click/dashboard/employers/employee-wizard/family',
    EMPLOYEE_WIZARD_TAXCREDIT = '/slip-click/dashboard/employers/employee-wizard/taxcredit',
    EMPLOYEE_WIZARD_SALLARY = '/slip-click/dashboard/employers/employee-wizard/sallary',
    EMPLOYEE_WIZARD_GEMEL = '/slip-click/dashboard/employers/employee-wizard/gemel',
    EMPLOYEE_WIZARD_SUMMARY = '/slip-click/dashboard/employers/employee-wizard/summary',
    EMPLOYEE_WIZARD_CONTRACT = '/slip-click/dashboard/employers/employee-wizard/contract',
    NEW_EMPLOYEE_START = '/slip-click/dashboard/employers/employee-new-start',
    REQUEST_LIST = '/slip-click/dashboard/employers/request-list',
    EDIT_EMPLOYER = '/slip-click/dashboard/employers/edit-employer',
    EMPLOYEES_LIST = '/slip-click/dashboard/employers/employees-list',
    MONTH_VIEW = '/slip-click/dashboard/employers/month-view',
    SLIPS_LIST = '/slip-click/dashboard/employers/slips-list',
    SLIP_WIZARD_DETAILS = '/slip-click/dashboard/employers/slip-wizard/details',
    NEW_EMPLOYEE_FILE_DETAILS = '/join-employee/dashboard/employers/new-employee-file/details',
    EMPLOYEE_CASE = '/join-employee/dashboard/employers/employee-case'
}

export const routerPathMapper: { [key in RouterPaths]?: RoutMapper } = {
    "/slip-click/dashboard": { isOpacity: true, backgroundImg: 'illustration-employer-dashboard' },
    "/slip-click/dashboard/employers": { isOpacity: true, backgroundImg: 'illustration-employer-dashboard' },
    "/slip-click/dashboard/employers/employer-new-start": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employer-wizard/details": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employer-wizard/representative": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employer-wizard/sallary": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employer-wizard/summary": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/success-new-employer": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employee-wizard/details": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/family": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/taxcredit": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/sallary": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/gemel": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/summary": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-wizard/contract": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/employee-new-start": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/success-new-employee": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/success-new-slip": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/success-edit-employee": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/slip-click/dashboard/employers/request-list": { isOpacity: true, backgroundImg: 'illustration-employer-dashboard' },
    "/slip-click/dashboard/employers/edit-employer": { isOpacity: false, backgroundImg: 'illustration-employer' },
    "/slip-click/dashboard/employers/employees-list": { isOpacity: true, backgroundImg: 'illustration-employer-dashboard' },
    "/slip-click/dashboard/employers/month-view": { isOpacity: true, backgroundImg: 'illustration-month' },
    "/slip-click/dashboard/employers/slips-list": { isOpacity: true, backgroundImg: 'illustration-employer-dashboard' },
    "/slip-click/dashboard/employers/slip-wizard/details": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/join-employee/dashboard/employers/new-employee-file/details": { isOpacity: false, backgroundImg: 'illustration-salary' },
    "/join-employee/dashboard/employers/employee-case": { isOpacity: false, backgroundImg: 'illustration-salary' }

};

export interface RoutMapper {
    isOpacity: boolean;
    backgroundImg: string;
}

export enum RelativeEmployerRoutes {
  EMPLOYER_NEW_START = 'employer-new-start',
  EMPLOYER_WIZARD = 'employer-wizard',
  EMPLOYER_WIZARD_DETAILS = 'details',
  EMPLOYER_WIZARD_SALLARY = 'sallary',
  EMPLOYER_WIZARD_REPRESENTATIVE = 'representative',
  EMPLOYER_WIZARD_SUMMARY = 'summary',
  EMPLOYEE_WIZARD = 'employee-wizard',
  EMPLOYEE_WIZARD_DETAILS = 'details',
  EMPLOYEE_WIZARD_CONTRACT = 'contract',
  EMPLOYEE_NEW_START = 'employee-new-start',
  SUCCESS_NEW_EMPLOYER = 'success-new-employer',
  SUCCESS_NEW_EMPLOYEE = 'success-new-employee',
  SUCCESS_EDIT_EMPLOYEE = 'success-edit-employee',
  SUCCESS_NEW_SLIP = 'success-new-slip',
  REQUEST_LIST = 'request-list',
  EDIT_EMPLOYER = 'edit-employer',
  EMPLOYEES_LIST = "employees-list",
  EMPLOYEE_WIZARD_FAMILY = 'family',
  EMPLOYEE_WIZARD_TAXCREDIT = 'taxcredit',
  EMPLOYEE_WIZARD_SALLARY = 'sallary',
  EMPLOYEE_WIZARD_GEMEL = 'gemel',
  EMPLOYEE_WIZARD_SUMMARY = 'summary',
  MONTH_VIEW = "month-view",
  SLIPS_LIST = "slips-list",
  SLIP_WIZARD_DETAILS = "details",
  SLIP_WIZARD = "slip-wizard",
  NEW_EMPLOYEE_FILE = 'new-employee-file',
  NEW_EMPLOYEE_FILE_DETAILS = 'details',
  EMPLOYEE_CASE = 'employee-case'
}

export const BASE_EMPLOYER_MODULE_ROUTE = '/slip-click/dashboard/employers/';

export type EmployerWizardStepInfo = WizardStepInfo<RelativeEmployerRoutes>
