import { Injectable } from "@angular/core";
import { AbstarctWizardService } from "./wizard-steps-data.service";
import { IFormBuilder, IFormGroup } from "@rxweb/types";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NewSlipWizardStep1, pensionFund, specialBenefits } from '../models/employer-wizard.model';
import { EmployeeHttpService } from '../../services/http/employee.service';
import { BASE_EMPLOYER_MODULE_ROUTE, RelativeEmployerRoutes } from "../models/Routing.model";
import { EmployeeListService } from "./employees-list.service";
import { requiredByOtherFieldByVal } from "../../../../shared/helpers/validatorsFn.helper";

@Injectable()
export class NewSlipWizardService extends AbstarctWizardService {
  private formBuilder: IFormBuilder = new FormBuilder();
  public cars: any[];
  public slipToUpdate: any;
  public month:string;
  constructor(
    private employeeHttpService: EmployeeHttpService,
    private employeeListService: EmployeeListService,
    private router: Router
  ) {
    super();
    //this.initialFormGroups();
  }

  public initialFormGroups(): void {
    this.formData.step1 = this.formBuilder.group<NewSlipWizardStep1>({
      // id: [, [Validators.required]],
      wageType: [this.slipToUpdate ? this.slipToUpdate.wageType : true],
      grossSalary: [this.slipToUpdate ? this.slipToUpdate.grossSalary : null, [Validators.required]],
      globalOvertime: [this.slipToUpdate ? this.slipToUpdate.globalOvertime : null],
      vacationDayExploitation: [this.slipToUpdate ? this.slipToUpdate.vacationDayExploitation : null],
      sickDayExploitation: [this.slipToUpdate ? this.slipToUpdate.sickDayExploitation : null],
      travellingExpenses: [this.slipToUpdate ? this.slipToUpdate.travellingExpenses : null],
      // vehicleValue: [, [Validators.required]],
      refundPhoneNumber: [this.slipToUpdate ? this.slipToUpdate.refundPhoneNumber : null],
      valuePhoneNumber: [this.slipToUpdate ? this.slipToUpdate.valuePhoneNumber : null],
      // carExpensesChecked: [false],
      carExpenses: [this.slipToUpdate ? this.slipToUpdate.carExpenses : null],

      manufacturerCode: [this.slipToUpdate ? this.slipToUpdate.manufacturerCode : null],
      model: [this.slipToUpdate ? this.slipToUpdate.model : null],
      carYear: [this.slipToUpdate ? this.slipToUpdate.carYear : null],

      employeeParticipation: [this.slipToUpdate ? this.slipToUpdate.employeeParticipation : null],
      telephoneExpensesByWorkerChecked: [true],
      sum: [this.slipToUpdate ? this.slipToUpdate.sum : null],
      totalAccount: [this.slipToUpdate ? this.slipToUpdate.totalAccount : null],
      advanceDeduction: [this.slipToUpdate ? this.slipToUpdate.advanceDeduction : null],
      mealExpenses: [this.slipToUpdate ? this.slipToUpdate.mealExpenses : null],
      mealValuations: [this.slipToUpdate ? this.slipToUpdate.mealValuations : null],
      mealDeduction: [this.slipToUpdate ? this.slipToUpdate.mealDeduction : null],
      otherExpenses: [this.slipToUpdate ? this.slipToUpdate.otherExpenses : null],
      hourRate: [this.slipToUpdate ? this.slipToUpdate.hourRate : null, [Validators.required]],
      totalHour: [this.slipToUpdate ? this.slipToUpdate.totalHour : null],
      totalOvertime100: [this.slipToUpdate ? this.slipToUpdate.totalOvertime100 : null],
      totalOvertime125: [this.slipToUpdate ? this.slipToUpdate.totalOvertime125 : null],
      totalOvertime150: [this.slipToUpdate ? this.slipToUpdate.totalOvertime150 : null],
      totalOvertime175: [this.slipToUpdate ? this.slipToUpdate.totalOvertime175 : null],
      totalOvertime200: [this.slipToUpdate ? this.slipToUpdate.totalOvertime200 : null],
      holidayPayment: [this.slipToUpdate ? this.slipToUpdate.holidayPayment : null],
      bonus: [this.slipToUpdate ? this.slipToUpdate.bonus : null],
      pensionIncludedPayment: [this.slipToUpdate ? this.slipToUpdate.pensionIncludedPayment : null],
      nonPensionIncludedPayment: [this.slipToUpdate ? this.slipToUpdate.nonPensionIncludedPayment : null],
      monthlyConvalescencePay: [this.slipToUpdate ? this.slipToUpdate.monthlyConvalescencePay : null],
      giftsValuationsIncluded: [this.slipToUpdate ? this.slipToUpdate.giftsValuationsIncluded : null],
      giftsValuationsNonIncluded: [this.slipToUpdate ? this.slipToUpdate.giftsValuationsNonIncluded : null],
      debtDeduction: [this.slipToUpdate ? this.slipToUpdate.debtDeduction : null],
      loanDeduction: [this.slipToUpdate ? this.slipToUpdate.loanDeduction : null],
      // specialBenefits: this.formBuilder.array<specialBenefits>([
      // ]),

    },
      {
        validators: [
          requiredByOtherFieldByVal('wageType', 'grossSalary', true),
          requiredByOtherFieldByVal('wageType', 'hourRate', false),
        ]
      });
  }
  public onSubmit() {
    const controls = (this.formData.step1 as IFormGroup<NewSlipWizardStep1>).controls
    this.slipToUpdate.grossSalary = Number(controls.grossSalary.value),
      this.slipToUpdate.globalOvertime = Number(controls.globalOvertime.value),
      this.slipToUpdate.vacationDayExploitation = Number(controls.vacationDayExploitation.value),
      this.slipToUpdate.sickDayExploitation = Number(controls.sickDayExploitation.value),
      this.slipToUpdate.travellingExpenses = Number(controls.travellingExpenses.value),
      this.slipToUpdate.refundPhoneNumber = Number(controls.refundPhoneNumber.value),
      this.slipToUpdate.valuePhoneNumber = Number(controls.valuePhoneNumber.value),
      this.slipToUpdate.carExpenses = Number(controls.carExpenses.value),

      this.slipToUpdate.manufacturerCode = Number(controls.manufacturerCode.value),
      this.slipToUpdate.model = Number(controls.model.value),
      this.slipToUpdate.carYear = controls.carYear.value,

      this.slipToUpdate.employeeParticipation = Number(controls.employeeParticipation.value),
      // telephoneExpensesByWorkerChecked= [true],
      this.slipToUpdate.sum = Number(controls.sum.value),
      this.slipToUpdate.totalAccount = Number(controls.totalAccount.value),

      this.slipToUpdate.mealExpenses = Number(controls.mealExpenses.value),
      this.slipToUpdate.mealValuations = Number(controls.mealValuations.value),
      this.slipToUpdate.mealDeduction = Number(controls.mealDeduction.value),
      this.slipToUpdate.otherExpenses = Number(controls.otherExpenses.value),
      this.slipToUpdate.hourRate = Number(controls.hourRate.value),
      this.slipToUpdate.totalHour = Number(controls.totalHour.value),
      this.slipToUpdate.totalOvertime100 = Number(controls.totalOvertime100.value),
      this.slipToUpdate.totalOvertime125 = Number(controls.totalOvertime125.value),
      this.slipToUpdate.totalOvertime150 = Number(controls.totalOvertime150.value),
      this.slipToUpdate.totalOvertime175 = Number(controls.totalOvertime175.value),
      this.slipToUpdate.totalOvertime200 = Number(controls.totalOvertime200.value),
      this.slipToUpdate.holidayPayment = Number(controls.holidayPayment.value),
      this.slipToUpdate.bonus = Number(controls.bonus.value),
      this.slipToUpdate.pensionIncludedPayment = Number(controls.pensionIncludedPayment.value),
      this.slipToUpdate.nonPensionIncludedPayment = Number(controls.nonPensionIncludedPayment.value),
      this.slipToUpdate.monthlyConvalescencePay = Number(controls.monthlyConvalescencePay.value),
      this.slipToUpdate.giftsValuationsIncluded = Number(controls.giftsValuationsIncluded.value),
      this.slipToUpdate.giftsValuationsNonIncluded = Number(controls.giftsValuationsNonIncluded.value),
      this.slipToUpdate.debtDeduction = Number(controls.debtDeduction.value),
      this.slipToUpdate.advanceDeduction = Number(controls.advanceDeduction.value),
      this.slipToUpdate.loanDeduction = Number(controls.loanDeduction.value),
      this.employeeHttpService.updateEmployeeSallary(this.slipToUpdate).subscribe(() => {
        //localStorage.removeItem('employerId');
        this.router.navigate([BASE_EMPLOYER_MODULE_ROUTE + RelativeEmployerRoutes.SUCCESS_NEW_SLIP]);
      })

  }
}
