
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { APIEndpoints } from '../../../../shared/models/ApiEndPoints.model';
import { EmployeeDTO, CopySalaryRequest, ProdeuceSalaryRequest } from '../../models/DTOs';
import { EmployeeDashboardMetaData } from '../../dashboard/models/employee.meta-data.model';

@Injectable()
export class EmployeeHttpService {

    constructor(
        private readonly httpClient: HttpClient) { }

    public createNewEmployee(req: EmployeeDTO): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_EMPLOYEE_ACTIONS}`;
        return this.httpClient.post<any>(url, req)
            .pipe(map(response => response))
    }

    public updateEmployee(req: EmployeeDTO): Observable<number> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_EMPLOYEE_ACTIONS}`;
        return this.httpClient.put<number>(url, req)
            .pipe(map(response => response))
    }

    public updateEmployeeSallary(req: EmployeeDTO): Observable<number> {
        const url = `${environment.baseUrl}/${APIEndpoints.UPDATE_SALARY_SLIP}`;
        return this.httpClient.put<number>(url, req)
            .pipe(map(response => response))
    }

    public getEmployeesList(id,date=""): Observable<EmployeeDashboardMetaData[]> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_EMPLOYEE_ACTIONS}`;
        return this.httpClient.get<EmployeeDashboardMetaData[]>(url,{params: {employersIds: id,salaryDate:date}})
            .pipe(map(response => response)) 
    }

    public removeEmployees(id: number[]): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_EMPLOYEE_ACTIONS}`;
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: id
        };
        return this.httpClient.delete<any>(url,httpOptions)
            .pipe(map(response => response)) 
    }

    public getTableByCode(code): Observable<any[]> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_TABLE}?code=${code}`;
        return this.httpClient.get<any[]>(url)
            .pipe(map(response => response)) 
    }

    public GetBankBranches(bankId): Observable<any[]> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_BAMK_BRANCHES}?bankId=${bankId}`;
        return this.httpClient.get<any[]>(url)
            .pipe(map(response => response)) 
    }

    public getCities(): Observable<any[]> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_CITIES_TABLE}`;
        return this.httpClient.get<any[]>(url)
            .pipe(map(response => response)) 
    }

    public getCars(): Observable<any[]> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_CARS_TABLE}`;
        return this.httpClient.get<any[]>(url)
            .pipe(map(response => response)) 
    }

    public CopySalaryDetails(req: CopySalaryRequest): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_COPY_SALARY_DETAILS}`;
        return this.httpClient.post<any>(url, req)
            .pipe(map(response => response))
    }

    public produceSalarySlip(req: ProdeuceSalaryRequest): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.MALAM_SLIP_ACTIONS}`;
        return this.httpClient.post<any>(url, req)
            .pipe(map(response => response))
    }

    public getSalarySlip( employeeId:number, employerId:number, salaryDate:string):Observable<string> {
        const url = `${environment.baseUrl}/${APIEndpoints.GET_ONE_SALARY_SLIP}?employeeId=${employeeId}&employerId=${employerId}&salaryDate=${salaryDate}`;
        return this.httpClient.get<any>(url)
        .pipe(map(response => response)) 
    } 
}
