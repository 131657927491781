<div class="header-container">
    <div class="isMobile mobileMenu">
        <img src="assets/icons-menu.svg" alt="icon" (click)="showMenu()">
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
    <div class="icon">
        <img src="assets/logo-logo.svg" alt="icon">
    </div>    
</div>
