import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCardBorder]'
})
export class CardBorderDirective implements OnInit {

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }
  
  public ngOnInit(): void {
    this.renderer.setStyle(this.element.nativeElement, 'border', 'solid 1px rgb(17, 122, 211, 0.4)')
    this.renderer.setStyle(this.element.nativeElement, 'border-top-right-radius', '25px')
    this.renderer.setStyle(this.element.nativeElement, 'border-bottom-left-radius', '25px')
  }

}
