import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorHandlerService } from '../services/error-handler.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  errorCode: { [key: number]: string } = {
    [120]:'המשתמש לא קיים במערכת',
    [125]:'סיסמה לא תקינה',
    [130]:'מעסיק לא נמצא',
    [140]:'עובד לא נמצא',
    [150]:'בקשה לא נמצא',
    [160]:'טופס לא נמצא',
    [10]:'המשתמש כבר קיים במערכת'
   }; 

  constructor(
      private errorHandlerService: ServerErrorHandlerService,
      private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(this.errorHandlerService.errorHandler.bind(this))
      )
  }
}
