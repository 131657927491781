import { IFormGroup } from '@rxweb/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidWizardStepForm, ValidWizardStepNumber } from '../models/employer-wizard.model';

export abstract class AbstarctWizardService {
  public formData: Partial<Record<ValidWizardStepNumber, IFormGroup<ValidWizardStepForm>>> = {};
  private _currentStep: number = 1;
  private currentStep: BehaviorSubject<number> = new BehaviorSubject<number>(this._currentStep);
  public abstract onSubmit(): any;
  public abstract initialFormGroups(): void;
  public setCurrentStep(step: number): void {
    this.currentStep.next(step);
    this._currentStep = step;
  }

  public get isStepValid(): boolean {
    return Object.values(this.formData).map(s => s.valid)[this._currentStep - 1];
  }

  public resetFormData(): void {
    Object.keys(this.formData).forEach((key) => this.formData[key].reset())
  }

  public get stepNumber(): Observable<number> {
    return this.currentStep.asObservable();
  }
}