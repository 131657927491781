import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() public text: string;
  @Input() public disabled: boolean;
  @Input() public width: number = 185;
  @Input() public isSmall: boolean = false;
  @Input() icon: string;
  @Output() public execute: EventEmitter<void> = new EventEmitter<void>();
  @Input() class: 'light' | 'dark' = 'light';
  constructor() { }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.execute.next();
  }
}
