import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-prepare-step',
  templateUrl: './info-prepare-step.component.html',
  styleUrls: ['./info-prepare-step.component.scss']
})
export class InfoPrepareStepComponent implements OnInit {
  @Input() public number: number;
  @Input() public iconPath: string;
  @Input() public text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
