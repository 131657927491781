
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenHandlerService } from '../services/token-handler-service';

@Injectable()
export class AdminAuthGuard implements CanActivate {

    constructor(private readonly router: Router, private tokenHandlerService: TokenHandlerService) { }
    public async canActivate(): Promise<boolean> {
        const token = sessionStorage.getItem('token');
        if (!token) {
            this.router.navigate(['login']);
            return false;
        }

        if(!JSON.parse(this.parseJwt(sessionStorage.getItem("token")).UserData).AdminUser){
            this.router.navigate(['main']);
        }

        // await this.tokenHandlerService.checkUserRole()
        //     .subscribe((res: boolean) => {
        //         if (!res)
        //             this.router.navigate(['main']);
        //     });
        return true;
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      };
}