
import { FullServerErrorResponse, ServerErrorResponse } from './../models/Error-server.model'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalComponent } from '../components/modal/modal.component';
@Injectable()
export class ServerErrorHandlerService {



    constructor(public dialogService: DialogService) { }
    public errorHandler(error: FullServerErrorResponse): Observable<string> {
        if (error.status == 401) {
            sessionStorage.removeItem('token');
            this["router"].navigate(['login']);
            throw throwError(error.error.title || "server error.");
        }
        let errorMessage = this['errorCode'][error.error.errorCode]
        if (!errorMessage)
            errorMessage = 'ארעה שגיאה'

        if (error.error.errorCode == 666 && !error.error.detail.includes("BadRequest")) {
            let errors = JSON.parse(error.error.detail.substring(error.error.detail.indexOf("-") + 1));
            if (errors.errors) {
                errors = errors.errors;
                errorMessage = "";
                //errorMessage += "<br />";
                let html = "<ul>";
                errors.forEach(element => {
                    html += "<li>" + element.errorDescriptionHE + "</li>"
                });
                html += "</ul>";
                errorMessage = errorMessage + html
            }
            else {
                errorMessage = errors.errorDescriptionHE;
            }

        }

        this['errorHandlerService'].dialogService.open(ModalComponent, {
            data: { text: errorMessage, approve: false },
            header: "שגיאה"
        });
        throw throwError(error.error.title || "server error.");



    }

}